<template>
    <div>
        <AuthLayout type="admin">
            <h1 class="mb-2">Reset your Password</h1>

            <span style="color: #555555">
                Your new password must be different from previous used
                passwords.
            </span>
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="resetPassword">
                    <div class="my-3">
                        <Input
                            type="password"
                            placeholder="Enter New Password"
                            v-model="formData.new_password"
                            name="Password"
                            rules="required|between:6,21"
                            autocomplete="current-password"
                            passwordToggleType="icon"
                        />
                    </div>
                    <div class="my-3">
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            v-model="formData.confirm_password"
                            name="Password"
                            rules="required|between:6,21"
                            autocomplete="current-password"
                            passwordToggleType="icon"
                        />
                    </div>

                    <Button
                        type="submit"
                        :disabled="
                            invalid ||
                            loading ||
                            formData.confirm_password !==
                                formData.new_password ||
                            !formData.confirm_password ||
                            !formData.new_password
                        "
                        :loading="loading"
                        class="mb-2"
                        theme="blue"
                    >
                        Reset Password
                    </Button>
                </form>
            </ValidationObserver>
        </AuthLayout>

        <Modal :active="showModal">
            <div class="flex flex__center">
                <div class="mb-3">
                    <img
                        src="@/assets/icons/success-icon.svg"
                        alt="success-icon"
                    />
                </div>
            </div>

            <div class="text-center mb-2">
                <span class="text-center text__light-grey">
                    Your password has been reset successfully!
                </span>
            </div>
            <div class="flex flex__center">
                <Button
                    theme="blue"
                    class="w-27"
                    @click="$router.push({ name: 'adminLogin' })"
                >
                    Login using new password
                </Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import Modal from "@/components/Modal";
import adminService from "../../../../services/api/adminService";

export default {
    name: "AdminForgotPassword",
    components: {
        AuthLayout,
        Modal
    },
    props: { token: { type: String } },
    data() {
        return {
            formData: {},
            showModal: false,
            loading: false
        };
    },
    mounted() {
        // console.log(this.token);
    },
    methods: {
        async resetPassword() {
            const { token } = this;

            this.loading = true;
            try {
                const response = await adminService.changePassword({
                    token,
                    new_password: this.formData.new_password
                });

                if (!response.data.error) {
                    this.showModal = true;
                } else {
                    this.$notify({
                        text: response.data.message,
                        title: "Error",
                        type: "error"
                    });
                }
            } catch (error) {
                this.$notify({
                    text: error,
                    title: "Error",
                    type: "error"
                });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped></style>
