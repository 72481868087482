var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AuthLayout',{attrs:{"type":"admin"}},[_c('h1',{staticClass:"mb-2"},[_vm._v("Reset your Password")]),_c('span',{staticStyle:{"color":"#555555"}},[_vm._v(" Your new password must be different from previous used passwords. ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('div',{staticClass:"my-3"},[_c('Input',{attrs:{"type":"password","placeholder":"Enter New Password","name":"Password","rules":"required|between:6,21","autocomplete":"current-password","passwordToggleType":"icon"},model:{value:(_vm.formData.new_password),callback:function ($$v) {_vm.$set(_vm.formData, "new_password", $$v)},expression:"formData.new_password"}})],1),_c('div',{staticClass:"my-3"},[_c('Input',{attrs:{"type":"password","placeholder":"Confirm Password","name":"Password","rules":"required|between:6,21","autocomplete":"current-password","passwordToggleType":"icon"},model:{value:(_vm.formData.confirm_password),callback:function ($$v) {_vm.$set(_vm.formData, "confirm_password", $$v)},expression:"formData.confirm_password"}})],1),_c('Button',{staticClass:"mb-2",attrs:{"type":"submit","disabled":invalid ||
                        _vm.loading ||
                        _vm.formData.confirm_password !==
                            _vm.formData.new_password ||
                        !_vm.formData.confirm_password ||
                        !_vm.formData.new_password,"loading":_vm.loading,"theme":"blue"}},[_vm._v(" Reset Password ")])],1)]}}])})],1),_c('Modal',{attrs:{"active":_vm.showModal}},[_c('div',{staticClass:"flex flex__center"},[_c('div',{staticClass:"mb-3"},[_c('img',{attrs:{"src":require("@/assets/icons/success-icon.svg"),"alt":"success-icon"}})])]),_c('div',{staticClass:"text-center mb-2"},[_c('span',{staticClass:"text-center text__light-grey"},[_vm._v(" Your password has been reset successfully! ")])]),_c('div',{staticClass:"flex flex__center"},[_c('Button',{staticClass:"w-27",attrs:{"theme":"blue"},on:{"click":function($event){return _vm.$router.push({ name: 'adminLogin' })}}},[_vm._v(" Login using new password ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }